<template>
  <div class="market-templates">
    <SportSidebar
      title="Templates"
      :selected-sport-id="selectedSportId"
      @update:selected-sport-id="updateSelectedSportId"
    />

    <div class="market-templates__main">
      <div class="market-templates__header">
        <div class="market-templates__text">
          <Heading>{{ title }}</Heading>
          <p>{{ subtitle }}</p>
        </div>

        <Button
          v-if="selectedSport && sportTemplates.length"
          icon="plus"
          variant="primary"
          @click="createNewSportTemplate"
          :loading="createNewSportTemplateProcessing"
        >
          New template
        </Button>
      </div>

      <div
        v-if="sportsLoading || sportTemplatesLoading"
        class="market-templates__content"
      >
        <Spinner />
      </div>

      <div
        v-else-if="sportTemplates.length"
        class="market-templates__content"
      >
        <SportTemplatesListView
          :sport-templates="sportTemplates"
          @reload="loadSportTemplates"
        />
      </div>

      <EmptyList
        v-else
        @on-create-new-template="createNewSportTemplate"
      />
    </div>
  </div>
</template>

<script>
import {
  debounce, find, set, filter,
} from 'lodash';
import {
  ref, computed, onMounted, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import * as api from '@/services/api';
import Heading from '@/components/common/Heading';
import SportSidebar from '@/components/common/SportSidebar';
import Spinner from '@/components/common/Spinner';
import Button from '@/components/common/Button';
import SportTemplatesListView from './SportTemplatesListView';
import EmptyList from './EmptyList';
import { ID as FOOTBALL_ID } from '@/services/helpers/football';

export default {
  components: {
    Heading,
    SportSidebar,
    Spinner,
    Button,
    SportTemplatesListView,
    EmptyList,
  },
  setup() {
    const sports = ref([]);
    const sportsLoading = ref(false);
    const loadSports = () => {
      sportsLoading.value = true;
      sports.value = [];
      return api.findAllSports()
        .then((sportsResponse) => {
          sports.value = filter(sportsResponse, (sport) => sport.sportId && sport.sportCoverageAvailablesBySportId.nodes?.[0].available);
        })
        .finally(() => {
          sportsLoading.value = false;
        });
    };

    const route = useRoute();
    const router = useRouter();
    const selectedSportId = computed(() => route.query.sport);
    const updateSelectedSportId = (sportId) => {
      const query = { sport: sportId };
      if (!query.sport) delete query.sport;
      router.replace({
        ...route,
        query,
      });
    };
    const selectedSport = computed(() => find(sports.value, { sportId: selectedSportId.value }));
    const title = computed(() => `${selectedSport.value?.sportName || 'All templates'}`);
    const subtitle = computed(() => {
      if (!selectedSportId.value) return 'List of all templates';
      const text = 'List of templates for';
      if (selectedSportId.value === FOOTBALL_ID) {
        return `${text} American football`;
      }
      return `${text} ${title.value.toLowerCase()}`;
    });

    const sportTemplates = ref([]);
    const sportTemplatesLoading = ref(false);
    const loadSportTemplates = () => {
      sportTemplatesLoading.value = true;
      sportTemplates.value = [];
      const variables = {
        filter: {
          sport: {
            limbo: {
              equalTo: false,
            },
          },
        },
      };
      if (selectedSport.value?.sportId) set(variables, 'filter.sportId.equalTo', selectedSport.value.sportId);
      api.findSportTemplates(variables)
        .then((sportTemplatesResponse) => {
          sportTemplates.value = sportTemplatesResponse;
        })
        .finally(() => {
          sportTemplatesLoading.value = false;
        });
    };
    const createNewSportTemplateProcessing = ref(false);
    const createNewSportTemplate = () => {
      createNewSportTemplateProcessing.value = true;
      api.createSportTemplate({
        name: 'Blank Template',
        sportId: selectedSport.value.sportId,
        isDefault: false,
        marketConfigurations: {},
      })
        .then((sportTemplate) => {
          router.push({
            name: 'sport-template',
            params: {
              id: sportTemplate.data.sportTemplateId,
            },
          });
        })
        .finally(() => {
          createNewSportTemplateProcessing.value = false;
        });
    };

    const backToTemplatesSports = () => router.push({ name: 'market-templates' });

    onMounted(() => {
      loadSports();
      if (!route.query.sport) loadSportTemplates();
    });
    watch(selectedSport, () => {
      sportTemplatesLoading.value = true;
      debounce(loadSportTemplates, 300)();
    });

    return {
      sports,
      sportsLoading,
      selectedSport,
      selectedSportId,
      updateSelectedSportId,
      title,
      subtitle,
      sportTemplates,
      sportTemplatesLoading,
      loadSportTemplates,
      createNewSportTemplateProcessing,
      createNewSportTemplate,
      backToTemplatesSports,
    };
  },
};
</script>

<style lang="scss">
.market-templates {
  .market-templates__main {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding-top: 8px;
  }

  .market-templates__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    padding: 32px;

    .market-templates__text {
      display: flex;
      flex-direction: column;
      position: relative;

      .icon {
        position: absolute;
        left: -25px;
        top: 5px;
        width: 18px;
        height: 18px;
        cursor: pointer;
        svg path {
          stroke: $black;
        }
      }
      p {
        font-size: 12px;
        color: $gray700;
      }
    }

    .icon svg path{
      stroke: #fff;
    }
  }

  .market-templates__content {
    padding: 32px 24px;
    height: calc(100% - 100px);
    overflow-y: auto;
  }
}
</style>
