<template>
  <div
    class="sport-templates-list"
    v-for="sportTemplate in sportTemplates"
    :key="sportTemplate.sportTemplateId"
    @click="editSportTemplate(sportTemplate)"
    @mouseover="setActionsColumnVisibility(sportTemplate, true)"
    @mouseleave="setActionsColumnVisibility(sportTemplate, false)"
  >
    <div class="sport-templates-list__column">
      <div class="sport-templates-list__row">
        <span class="sport-templates-list__title">
          {{ sportTemplate.name }}
        </span>
      </div>

      <div class="sport-templates-list__row">
        Includes {{ sportTemplate.marketTemplates.nodes?.[0]?.count }} markets
        &bull;
        Applied to {{ sportTemplate.competitionTemplates.nodes?.[0]?.count }} competitions
        <span v-if="sportTemplate.default">
          &bull;
        </span>
        <span
          v-if="sportTemplate.default"
          class="sport-templates-list__default"
        >
          Default template
        </span>
      </div>
    </div>

    <div
      v-show="isActionsColumnVisible(sportTemplate) || isSportTemplateDropdownLoading(sportTemplate)"
      class="sport-templates-list__column sport-templates-list__column--horizontal"
    >
      <Button
        variant="primary"
        :to="{ name: 'sport-template', params: { id: sportTemplate.sportTemplateId } }"
      >
        Edit template
      </Button>

      <Dropdown
        class="sport-template-more-dropdown"
        icon="more-dots"
        :loading="isSportTemplateDropdownLoading(sportTemplate)"
        hide-chevron
        right
      >
        <DropdownItem
          @click="setSportTemplateAsDefault(sportTemplate)"
          clickable
          :disabled="sportTemplate.default"
        >
          Make default
        </DropdownItem>

        <DropdownItem
          @click="cloneSportTemplate(sportTemplate)"
          clickable
        >
          Duplicate
        </DropdownItem>

        <DropdownItem
          class="dropdown-item--danger"
          @click="deleteSportTemplate(sportTemplate)"
          :disabled="sportTemplate.default"
          clickable
        >
          Delete
        </DropdownItem>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import { set } from 'lodash';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import * as api from '@/services/api';
import Button from '@/components/common/Button';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';

export default {
  components: {
    Button,
    Dropdown,
    DropdownItem,
  },
  props: {
    sportTemplates: {
      type: Array,
      required: true,
    },
  },
  emits: {
    reload: {},
  },
  setup(_, { emit }) {
    const moreDropdownVisibility = ref({});
    const setMoreDropdownVisibility = ({ sportTemplateId }, isVisible) => {
      set(moreDropdownVisibility.value, sportTemplateId, isVisible);
    };
    const isMoreDropdownVisible = ({ sportTemplateId }) => moreDropdownVisibility.value[sportTemplateId];

    const actionsColumnVisibility = ref({});
    const setActionsColumnVisibility = (sportTemplate, isVisible) => {
      set(actionsColumnVisibility.value, sportTemplate.sportTemplateId, isVisible);
      setMoreDropdownVisibility(sportTemplate, false);
    };
    const isActionsColumnVisible = ({ sportTemplateId }) => actionsColumnVisibility.value[sportTemplateId];

    const router = useRouter();
    const editSportTemplate = ({ sportTemplateId }) => {
      router.push({
        name: 'sport-template',
        params: {
          id: sportTemplateId,
        },
      });
    };

    const setSportTemplateAsDefaultProcessing = ref({});
    const isSetSportTemplateAsDefaultProcessing = ({ sportTemplateId }) => setSportTemplateAsDefaultProcessing.value[sportTemplateId];
    const setSportTemplateAsDefault = ({ sportTemplateId, default: isDefault }) => {
      if (isDefault) {
        setActionsColumnVisibility({ sportTemplateId }, false);
        return;
      }

      setSportTemplateAsDefaultProcessing.value[sportTemplateId] = true;
      api.setSportTemplateAsDefault(sportTemplateId)
        .then(() => {
          emit('reload');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setSportTemplateAsDefaultProcessing.value[sportTemplateId] = false;
          setActionsColumnVisibility({ sportTemplateId }, false);
        });
    };

    const cloneSportTemplateProcessing = ref({});
    const isCloneSportTemplateProcessing = ({ sportTemplateId }) => cloneSportTemplateProcessing.value[sportTemplateId];
    const cloneSportTemplate = (sportTemplate) => {
      cloneSportTemplateProcessing.value[sportTemplate.sportTemplateId] = true;
      api.cloneSportTemplate(sportTemplate.sportTemplateId)
        .then(() => {
          emit('reload');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          cloneSportTemplateProcessing.value[sportTemplate.sportTemplateId] = false;
          setActionsColumnVisibility(sportTemplate, false);
        });
    };

    const deleteSportTemplateProcessing = ref({});
    const isDeleteSportTemplateProcessing = ({ sportTemplateId }) => deleteSportTemplateProcessing.value[sportTemplateId];
    const deleteSportTemplate = ({ sportTemplateId, default: isDefault }) => {
      if (isDefault) return;
      deleteSportTemplateProcessing.value[sportTemplateId] = true;
      api.deleteSportTemplate(sportTemplateId)
        .then(() => {
          emit('reload');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          deleteSportTemplateProcessing.value[sportTemplateId] = false;
          setActionsColumnVisibility({ sportTemplateId }, false);
        });
    };

    const isSportTemplateDropdownLoading = (sportTemplate) => isSetSportTemplateAsDefaultProcessing(sportTemplate) || isDeleteSportTemplateProcessing(sportTemplate) || isCloneSportTemplateProcessing(sportTemplate);

    return {
      setMoreDropdownVisibility,
      isMoreDropdownVisible,
      setActionsColumnVisibility,
      isActionsColumnVisible,
      editSportTemplate,
      isSetSportTemplateAsDefaultProcessing,
      setSportTemplateAsDefault,
      isCloneSportTemplateProcessing,
      cloneSportTemplate,
      isDeleteSportTemplateProcessing,
      deleteSportTemplate,
      isSportTemplateDropdownLoading,
    };
  },
};
</script>

<style lang="scss">
.sport-templates-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 12px;
  margin-bottom: 4px;
  background-color: #fff;
  box-shadow: inset 0px -1px 0px #F0F0F0;
  font-size: 12px;
  color: #A9A9A9;
  cursor: pointer;

  .sport-templates-list__row {
    margin-bottom: 6px;
  }

  .sport-templates-list__title {
    color: #191414;
    font-size: 14px;
    margin-bottom: 6px;
  }

  .sport-templates-list__default {
    color: #175FFF;
    font-size: 12px;
    font-style: normal;
  }

  .sport-templates-list__column.sport-templates-list__column--horizontal {
    display: flex;
    align-items: center;
  }

  .sport-template-more-dropdown {
    margin-left: 8px;

    .button {
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
    }

    .dropdown-item {
      font-size: 14px;

      &.dropdown-item--danger {
        color: #f00;
      }
    }
  }
}
</style>
