<template>
  <div class="emtpy-list__root">
    <p>You haven't created any templates for this sport.</p>
    <p>Click on the button bellow to start creating new template.</p>
    <Button
      icon="plus"
      variant="primary"
      @click="createNewSportTemplate"
    >
      New template
    </Button>
  </div>
</template>

<script>
import Button from '@/components/common/Button';

export default {
  emits: ['onCreateNewTemplate'],
  components: {
    Button,
  },
  setup(props, { emit }) {
    const createNewSportTemplate = () => { emit('onCreateNewTemplate'); };

    return {
      createNewSportTemplate,
    };
  },
};
</script>

<style lang="scss">
.emtpy-list__root {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 14px;
    color: $gray700;

    & .button {
        margin-top: 28px;

        .icon svg path{
            stroke: #fff;
        }
    }
}
</style>
